<template>
<div>
<html>
    <div>
        <div>
            <div style="margin:0 auto;padding:0px" bgcolor="#F6F7F9">
                <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" style="width:100%;" role="presentation">
                    <tbody>
                        <tr>
                            <td>
                                <p aria-hidden="true" style="padding-top:0;font-size:0px;line-height:0px;color:#ffffff;background-color:#ffffff;display:none" align="center"></p>
                            </td>
                        </tr>
                        <tr>
                            <td align="center" valign="top" bgcolor="#F6F7F9" background="/assets/temp/email_background.png" style="background-image:url('https://ci6.googleusercontent.com/proxy/52_MxBHkWdB3Qln5MO-RGLWGOIYTKIcyPkLIAYs83RTqkNkWrW4-C3wzP5UP0rc6dbcdmLu2DeQw8ts4KR_sS5mqXweoG43k07BwS08fTut9E6TTZAdzDNgjmPl8FOg=s0-d-e1-ft#https://services.google.com/fh/files/emails/firebase_template_body_bg_2x.png');background-size:100% 100%;background-repeat:no-repeat;background-position:top center;padding:0 20px">
                                <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" style="max-width:600px;width:100%;" role="presentation">
                                    <tbody>
                                        <tr>
                                            <td align="center" valign="top">
                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" role="presentation">
                                                    <tbody>
                                                        <tr>
                                                            <td align="center" valign="top" style="font-family:Roboto,Helvetica,Arial,sans-serif;font-size:11px;color:#ffffff;line-height:22px;padding-top:12px;padding-bottom:30px">
                                                                <a href="https://app.cyruspay.com.au" data-saferedirecturl="https://app.cyruspay.com.au" style="text-decoration:underline;color:#000000;font-weight:500" target="_blank">View our web cyruspay</a> 
                                                                <span style="color:#000000"> if this email isn't displaying well.</span>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td align="center" valign="top" style="padding-bottom:33px">
                                                                <table border="0" cellpadding="0" cellspacing="0" align="center" role="presentation">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td aria-hidden="true" align="center" valign="top" style="padding-right:8px">
                                                                                <a href="https://app.cyruspay.com.au" data-saferedirecturl="https://app.cyruspay.com.au" style="text-decoration:none;color:#ffffff" target="_blank">
                                                                                    <img src="/assets/temp/email_logo.png" width="197" title="Firebase" style="display:block;height:auto">
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td align="center" valign="top" bgcolor="#ffffff" style="border-radius:16px;border:0px solid #e0e0e0">
                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" role="presentation">
                                                                    <tbody>

                                                                        <tr>
                                                                            <td align="center" valign="top" style="padding:25px 32px 0">
                                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" role="presentation">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td align="left" valign="top">
                                                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" role="presentation">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td height="5" align="left" valign="top" style="line-height:5px;display:none">
                                                                                                                <img src="/assets/temp/email_line.gif" width="1" height="1" border="0" alt="" style="display:block">
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td align="center" valign="top" style="font-family:Google Sans,Roboto,Helvetica,Arial,sans-serif;font-size:26px;color:#212121;line-height:39px;font-weight:500;padding-bottom:15px"> 
                                                                                                                <b style="color: #09b585">Welcome to Cyruspay</b>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td height="5" align="left" valign="top" style="line-height:5px;display:none">
                                                                                                                <img src="/assets/temp/email_line.gif" width="1" height="1" border="0" alt="" style="display:block">
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td align="left" valign="top">
                                                                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" role="presentation">
                                                                                                                    <tbody>
                                                                                                                        <tr>
                                                                                                                            <td aria-hidden="true" align="center" valign="top">
                                                                                                                                <a href="#" data-saferedirecturl="#" style="text-decoration:none;color:#ffffff" target="_blank"> 
                                                                                                                                    <img src="/assets/temp/email_banner.png" width="534" style="display:block;height:auto;border-radius:10px">
                                                                                                                                    <div> 
                                                                                                                                        <img src="/assets/temp/email_banner.png" width="534" style="display:block;border-radius:10px;height:0px"> 
                                                                                                                                    </div>
                                                                                                                                </a>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td height="0" align="left" valign="top" style="line-height:0px;display:none">
                                                                                                                <img src="/assets/temp/email_line.gif" width="1" height="1" border="0" alt="" style="display:block">
                                                                                                            </td>
                                                                                                        </tr>

                                                                                                        <!-- Content  -->
                                                                                                        <tr>
                                                                                                            <td align="center" valign="top" style="font-family:Google Sans,Roboto,Helvetica,Arial,sans-serif;font-size:18px;color:#212121;line-height:27px;font-weight:500;padding-bottom:6px;padding-top:15px">
                                                                                                                Confirm your email address
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td height="6" align="left" valign="top" style="line-height:6px;display:none">
                                                                                                                <img src="/assets/temp/email_line.gif" width="1" height="1" border="0" alt="" style="display:block">
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td align="left" valign="top" style="font-family:Roboto,Helvetica,Arial,sans-serif;font-size:14px;color:#757575;line-height:21px">
                                                                                                                Thank you for signing up to use Cyruspay. Once you click on the link below, your account will become active. You will be redirected to enter your Username and Password once again - but
                                                                                                                <strong>
                                                                                                                    please note that your Username is the email address you used for signup and the password is the one you set at 
                                                                                                                    <a href="https://app.cyruspay.com.au/login" data-saferedirecturl="https://app.cyruspay.com.au/login" style="text-decoration:none;color:#1a73e8" target="_blank">signup</a>.
                                                                                                                </strong>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td height="20" align="left" valign="top" style="line-height:20px">
                                                                                                                <img src="/assets/temp/email_line.gif" width="1" height="1" border="0" alt="" style="display:block">
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td align="center" valign="top">
                                                                                                                <table border="0" cellpadding="0" cellspacing="0" align="center" role="presentation">
                                                                                                                    <tbody>
                                                                                                                        <tr>
                                                                                                                            <td align="left" valign="top">
                                                                                                                                <table cellspacing="0" cellpadding="0" width="94" role="presentation">
                                                                                                                                    <tbody>
                                                                                                                                        <tr>
                                                                                                                                            <td aria-hidden="true" align="center" valign="top">
                                                                                                                                                <a href="#" data-saferedirecturl="#" target="_blank">
                                                                                                                                                <img src="/assets/temp/email_line.gif" width="100%" height="6" border="0" alt="" style="display:block"></a>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                        <tr>
                                                                                                                                            <td style="border-radius:8px;background-color:#1a73e8;color:#ffffff!important;text-align:center;vertical-align:middle" align="center">
                                                                                                                                                <a href="#" data-saferedirecturl="#" style="display:block;border-radius:8px;color:#ffffff;text-decoration:none;font-family:Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:14px;line-height:21px;font-weight:500;border-top:8px solid #1a73e8;border-right:16px solid #1a73e8;border-bottom:8px solid #1a73e8;border-left:16px solid #1a73e8;text-align:center;white-space:nowrap;letter-spacing:0.25px" target="_blank">
                                                                                                                                                    Confirm email
                                                                                                                                                </a>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                        <tr>
                                                                                                                                            <td aria-hidden="true" align="center" valign="top">
                                                                                                                                                <a href="#" target="_blank" data-saferedirecturl="#">
                                                                                                                                                <img src="/assets/temp/email_line.gif" width="100%" height="5" border="0" alt="" style="display:block"></a>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </tbody>
                                                                                                                                </table>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td height="20" align="left" valign="top" style="line-height:20px">
                                                                                                                <img src="/assets/temp/email_line.gif" width="1" height="20" border="0" alt="" style="display:block">
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <!-- ./ Content  -->

                                                                                                        <!-- Link Application  -->
                                                                                                        <tr>
                                                                                                            <td height="1" align="left" valign="top" bgcolor="#e0e0e0" style="line-height:1px">
                                                                                                                <img src="/assets/temp/email_line.gif" width="1" height="1" border="0" alt="" style="display:block">
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td align="center" valign="top" style="font-family:Google Sans,Roboto,Helvetica,Arial,sans-serif;font-size:18px;color:#212121;line-height:27px;font-weight:500;padding:31px 0 11px"> 
                                                                                                                Link Application 
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td align="left" valign="top">
                                                                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" align="left" role="presentation">
                                                                                                                    <tbody>
                                                                                                                        <tr>
                                                                                                                            <td align="left" valign="top">
                                                                                                                                <table border="0" cellpadding="0" cellspacing="0" width="259" align="left" role="presentation">
                                                                                                                                    <tbody>
                                                                                                                                        <tr>
                                                                                                                                            <td aria-hidden="true" align="center" valign="top">
                                                                                                                                                <a href="#" data-saferedirecturl="#" style="text-decoration:none;color:#ffffff" target="_blank"> 
                                                                                                                                                    <img src="/assets/temp/google_play.png" width="173" style="display:block;height:auto">
                                                                                                                                                    <div> <img src="/assets/temp/google_play.png" width="173" style="display:block;height:0px"> </div>
                                                                                                                                                </a>
                                                                                                                                            </td>
                                                                                                                                            <td aria-hidden="true" align="center" valign="top" style="display:none">
                                                                                                                                                <a href="#" data-saferedirecturl="#" style="text-decoration:none;color:#ffffff" target="_blank"> 
                                                                                                                                                    <img src="/assets/temp/google_play.png" width="258" style="display:block;height:auto">
                                                                                                                                                    <div> <img src="/assets/temp/google_play.png" width="258" style="display:block;height:0px"> </div>
                                                                                                                                                </a>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                        <tr>
                                                                                                                                            <td align="center" valign="top" style="font-family:Roboto,Helvetica,Arial,sans-serif;font-size:14px;color:#757575;line-height:21px;padding:12px 0px 2px 0px">
                                                                                                                                                <strong> (Android) </strong>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                        <!--
                                                                                                                                        <tr>
                                                                                                                                            <td align="center" valign="top" style="font-family:Roboto,Helvetica,Arial,sans-serif;font-size:14px;color:#1a73e8;line-height:21px;font-weight:500;padding:30px 0 20px 0px">
                                                                                                                                                <a href="#" data-saferedirecturl="#" style="text-decoration:none;color:#1a73e8" target="_blank">
                                                                                                                                                    <strong>Download</strong>
                                                                                                                                                </a>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                        -->
                                                                                                                                    </tbody>
                                                                                                                                </table>
                                                                                                                            </td>
                                                                                                                            <td width="8" height="20" align="left" valign="top" style="line-height:20px">
                                                                                                                                <img src="/assets/temp/email_line.gif" width="1" height="1" border="0" alt="" style="display:block">
                                                                                                                            </td>
                                                                                                                            <td align="center" valign="top">
                                                                                                                                <table border="0" cellpadding="0" cellspacing="0" width="259" align="center" role="presentation">
                                                                                                                                    <tbody>
                                                                                                                                        <tr>
                                                                                                                                            <td aria-hidden="true" align="center" valign="top">
                                                                                                                                                <a href="#" data-saferedirecturl="#" style="text-decoration:none;color:#ffffff" target="_blank"> 
                                                                                                                                                    <img src="/assets/temp/app_store.png" width="173" style="display:block;height:auto">
                                                                                                                                                    <div> <img src="/assets/temp/app_store.png" width="173" style="display:block;height:0px"> </div>
                                                                                                                                                </a>
                                                                                                                                            </td>
                                                                                                                                            <td aria-hidden="true" align="center" valign="top" style="display:none">
                                                                                                                                                <a href="#" data-saferedirecturl="#" style="text-decoration:none;color:#ffffff" target="_blank"> 
                                                                                                                                                    <img src="/assets/temp/app_store.png" width="258" style="display:block;height:auto">
                                                                                                                                                    <div> <img src="/assets/temp/app_store.png" width="258" style="display:block;height:0px"> </div>
                                                                                                                                                </a>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                        <tr>
                                                                                                                                            <td align="center" valign="top" style="font-family:Roboto,Helvetica,Arial,sans-serif;font-size:14px;color:#757575;line-height:21px;padding:12px 0px 25px 0px">
                                                                                                                                                <strong> (iOS) </strong>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                        <!--
                                                                                                                                        <tr>
                                                                                                                                            <td align="center" valign="top" style="font-family:Roboto,Helvetica,Arial,sans-serif;font-size:14px;color:#1a73e8;line-height:21px;font-weight:500;padding:7px 0 20px 0px">
                                                                                                                                                <a href="#" data-saferedirecturl="#" style="text-decoration:none;color:#1a73e8" target="_blank">
                                                                                                                                                    <strong>Download</strong>
                                                                                                                                                </a>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                        -->
                                                                                                                                    </tbody>
                                                                                                                                </table>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td align="left" valign="top">
                                                                                                                <table role="presentation" cellpadding="0" cellspacing="0" border="0">
                                                                                                                    <tbody>
                                                                                                                        <tr>
                                                                                                                            <td align="left" valign="top" style="font-family:Roboto,Helvetica,Arial,sans-serif;font-size:14px;color:#757575;line-height:21px;padding:12px 0 18px 0px"> 
                                                                                                                                Website:
                                                                                                                                <a href="http://cyruspay.com.au" data-saferedirecturl="http://cyruspay.com.au" style="text-decoration:none;color:#1a73e8" target="_blank">wwww.cyruspay.com.au</a> 
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <!-- ./ Link Application  -->

                                                                                                        <!-- The Cyruspay Platform  -->
                                                                                                        <tr>
                                                                                                            <td height="1" align="left" valign="top" bgcolor="#e0e0e0" style="line-height:1px">
                                                                                                                <img src="/assets/temp/email_line.gif" width="1" height="1" border="0" alt="" style="display:block">
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td align="left" valign="top" style="padding-top:32px;padding-bottom:32px">
                                                                                                                <table cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                    <tbody>
                                                                                                                        <tr>
                                                                                                                            <td align="left" valign="top">
                                                                                                                                <table cellpadding="0" cellspacing="0" border="0" role="presentation">
                                                                                                                                    <tbody>
                                                                                                                                        <tr>
                                                                                                                                            <td align="left" valign="center">
                                                                                                                                                <table cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                                                    <tbody>
                                                                                                                                                        <tr>
                                                                                                                                                            <td align="left" valign="center" style="font-family:Roboto,Helvetica,Arial,sans-serif;font-size:14px;color:#212121;line-height:21px;padding:0px 0px 6px 0px;font-weight:500"> 
                                                                                                                                                                The Cyruspay Platform:
                                                                                                                                                            </td>
                                                                                                                                                        </tr>
                                                                                                                                                        <tr>
                                                                                                                                                            <td align="left" valign="center" style="font-family:Roboto,Helvetica,Arial,sans-serif;font-size:14px;color:#757575;line-height:21px;padding:0px 0px 6px 0px"> 
                                                                                                                                                                Integrates easily with your HCM<br>
                                                                                                                                                                Automates repeat tasks<br>
                                                                                                                                                                Standardizes global payroll data<br>
                                                                                                                                                                Delivers multi-country reporting<br>
                                                                                                                                                            </td>
                                                                                                                                                        </tr>
                                                                                                                                                    </tbody>
                                                                                                                                                </table>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </tbody>
                                                                                                                                </table>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <!-- ./ The Cyruspay Platform  -->

                                                                                                        <tr>
                                                                                                            <td height="1" align="left" valign="top" bgcolor="#e0e0e0" style="line-height:1px">
                                                                                                                <img src="/assets/temp/email_line.gif" width="1" height="1" border="0" alt="" style="display:block">
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td height="30" align="left" valign="top" style="line-height:30px">
                                                                                                                <img src="/assets/temp/email_line.gif" width="1" height="1" border="0" alt="" style="display:block">
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td align="center" valign="top" style="font-family:Google Sans,Roboto,Helvetica,Arial,sans-serif;font-size:18px;color:#212121;line-height:27px;font-weight:500;padding: 0px 0px 14px"> 
                                                                                                                You need to contact us ?
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td align="center" valign="top" style="padding:0px 0">
                                                                                                                <table border="0" cellpadding="0" cellspacing="0" align="center" role="presentation">
                                                                                                                    <tbody>
                                                                                                                        <tr>
                                                                                                                            <td aria-hidden="true" align="center" valign="top">
                                                                                                                                <a href="https://www.facebook.com" data-saferedirecturl="https://www.facebook.com" style="text-decoration:none;color:#ffffff" target="_blank"> 
                                                                                                                                    <img src="/assets/temp/email_facebook_gray.png" width="30" title="Facebook" style="display:block;height:auto">
                                                                                                                                    <div> 
                                                                                                                                        <img src="/assets/temp/email_facebook_blue.png" width="30" title="Facebook" style="display:block;height:0px"> 
                                                                                                                                    </div>
                                                                                                                                </a>
                                                                                                                            </td>
                                                                                                                            <td width="16" height="1" align="left" valign="top" style="line-height:1px">
                                                                                                                                <img src="/assets/temp/email_line.gif" width="1" height="1" border="0" alt="" style="display:block">
                                                                                                                            </td>
                                                                                                                            <td aria-hidden="true" align="center" valign="top">
                                                                                                                                <a href="https://twitter.com" data-saferedirecturl="https://twitter.com" style="text-decoration:none;color:#ffffff" target="_blank"> 
                                                                                                                                    <img src="/assets/temp/email_twitter_gray.png" width="30" title="Twitter" style="display:block;height:auto">
                                                                                                                                    <div> 
                                                                                                                                        <img src="/assets/temp/email_twitter_blue.png" width="30" title="Twitter" style="display:block;height:0px"> 
                                                                                                                                    </div>
                                                                                                                                </a>
                                                                                                                            </td>
                                                                                                                            <td width="16" height="1" align="left" valign="top" style="line-height:1px">
                                                                                                                                <img src="/assets/temp/email_line.gif" width="1" height="1" border="0" alt="" style="display:block">
                                                                                                                            </td>
                                                                                                                            <td aria-hidden="true" align="center" valign="top">
                                                                                                                                <a href="https://www.youtube.com" data-saferedirecturl="https://www.youtube.com" style="text-decoration:none;color:#ffffff" target="_blank"> 
                                                                                                                                    <img src="/assets/temp/email_youtube_gray.png" width="30" title="YouTube" style="display:block;height:auto">
                                                                                                                                    <div> 
                                                                                                                                        <img src="/assets/temp/email_youtube_blue.png" width="30" title="YouTube" style="display:block;height:0px"> 
                                                                                                                                    </div>
                                                                                                                                </a>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td height="30" align="left" valign="top" style="line-height:30px">
                                                                                                                <img src="/assets/temp/email_line.gif" width="1" height="1" border="0" alt="" style="display:block">
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                        
                                                                        <tr>
                                                                            <td align="left" valign="top" bgcolor="#f2f4f5" style="padding:30px 32px 24px;border-bottom-left-radius:16px;border-bottom-right-radius:16px">
                                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" role="presentation">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td align="left" valign="top">
                                                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" role="presentation">
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td align="left" valign="top" style="font-family:Roboto,Helvetica,Arial,sans-serif;font-size:12px;color:#606162;line-height:18px;padding:0 0 12px"> 
                                                                                                                For more information, you can login to the website 
                                                                                                                <a href="https://app.cyruspay.com.au" data-saferedirecturl="https://app.cyruspay.com.au" style="text-decoration:none;color:#1967d2;font-weight:500" target="_blank">
                                                                                                                    app.cyruspay.com.au
                                                                                                                </a>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td align="center" valign="top">
                                                                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" role="presentation">
                                                                                                                    <tbody>
                                                                                                                        <tr>
                                                                                                                            <td align="left" valign="top">
                                                                                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" align="left" role="presentation">
                                                                                                                                    <tbody>
                                                                                                                                        <tr>
                                                                                                                                            <td align="left" valign="top">
                                                                                                                                                <table cellpadding="0" cellspacing="0" border="0" role="presentation">
                                                                                                                                                    <tbody>
                                                                                                                                                        <tr>
                                                                                                                                                            <td aria-hidden="true" align="left" valign="top" style="padding-top:21px">
                                                                                                                                                                <h3 width="118" title="Cyruspay" style="display:block;height:auto">
                                                                                                                                                                    <b style="color: #5F6368">Cyruspay</b>
                                                                                                                                                                </h3>
                                                                                                                                                            </td>
                                                                                                                                                        </tr>
                                                                                                                                                    </tbody>
                                                                                                                                                </table>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </tbody>
                                                                                                                                </table>
                                                                                                                            </td>
                                                                                                                            <td align="right" valign="top">
                                                                                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" align="right" role="presentation">
                                                                                                                                    <tbody>
                                                                                                                                        <tr>
                                                                                                                                            <td align="right" valign="top" style="font-family:Roboto,Helvetica,Arial,sans-serif;font-size:12px;color:#606162;line-height:18px;padding:0 0 0">
                                                                                                                                                <a href="#m_6009720710001840022_" style="text-decoration:none;color:#606162">
                                                                                                                                                    Cyruspay<br>
                                                                                                                                                    The Cyruspay Team<br>
                                                                                                                                                    <i>Friday, October 30, 2020 2:49:46 AM</i>
                                                                                                                                                </a>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </tbody>
                                                                                                                                </table>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td width="16" height="137" align="left" valign="top" style="line-height:137px">
                                                                <img src="/assets/temp/email_line.gif" width="1" height="1" border="0" alt="" style="display:block">
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <img height="1" width="3" src="/assets/temp/email_footer.gif">
            </div>
        </div>
    </div>



    <h1>This is an test page</h1>
    <form class="form-login" v-on:submit.prevent="_test()" action="" method="post">
        <div class="login">
            <button id="btn_test" class="submit-btn btn btn-primary" type="submit">
                <span class="span">IpClient</span>
            </button>
        </div>
    </form>
    <div class="col-sm-12 invoice-col" style="overflow: scroll; text-align: left">
        <pre id="result_data">Result for test</pre>
    </div>
</html>
</div>
</template>

<script>
import ApiManager from "../../src/api/ApiManager";
import * as Logs from '@/utils/Logs'
export default {
    name: "Test",
    props: [],
    components: {},
    mounted() {},
    destroyed() {},
    data() {
        return {};
    },
    methods: {
        _test() {
            document.getElementById("result_data").textContent = "Waiting...";
            document.getElementById("result_data").style.color = 'blue';
            document.getElementById("btn_test").setAttribute("disabled", "disabled");
            ApiManager.get("api/Auth/IpClient")
                .then((response) => {
                   // Logs.json("Success IpClient", response);
                    document.getElementById("result_data").textContent = JSON.stringify(response, null, 2);
                    document.getElementById("result_data").style.color = 'green';
                    document.getElementById("btn_test").removeAttribute("disabled");
                }).catch((error) => {
                    Logs.json("Error IpClient", error);
                    document.getElementById("result_data").textContent = JSON.stringify(error, undefined, 2);
                    document.getElementById("result_data").style.color = 'red';
                    document.getElementById("btn_test").removeAttribute("disabled");
                });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
</style>
